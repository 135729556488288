import React from 'react';
import videoPlaceholder from './videoPlaceholder.svg';
import s from './Progress.module.scss';
import Button from "../../Components/Button/Button";
import 'react-circular-progressbar/dist/styles.css';
import Footer from "../../Components/Footer/Footer";
import api from "../api";
import { formatDurationFromSeconds} from "../../Helpers/FormatHelper";
import localize from '../../Localizations/Localize';
import Modal from "./Modal/Modal";
import qs from "query-string";
import Loader from "../../Components/Loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";
import {LocalizationContext} from "../../Localizations/LocalizationContext";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import windowSize from "react-window-size";
import EventPicture from "../../Components/EventPicture/EventPicture";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import Edit from "./Edit/Edit";
import {storageAccessToken} from "../../constants";
import moment from "moment";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Process from "./Process/Process";

class Progress extends React.Component {
    state = {
        data: {},
        showModal: false,
        totalVideoLength: '00:00:00',
        participantCount: 0
    };

    get key() {
        const { key } = qs.parse(this.props.location.search);
        return key;
    }

    get id() {
        return this.props.match.params.id;
    }

    get processEndedWithError() {
        const { data } = this.state;
        return data.process && data.process.status === 'Error';
    }

    get inProgress() {
        const { data } = this.state;
        return data.process || data.status === 'PreparePreview';
    }

    get videoLengthCalculated() {
        const { data, totalVideoLength } = this.state;
        const calculatedStatuses = ['Editing', 'SpliceProcessing', 'PreparePreview', 'Done'];
        return calculatedStatuses.indexOf(data.statuse) !== -1 || moment.duration(totalVideoLength).asSeconds() > 0;
    }

    componentWillMount() {
        window.recaptchaOptions = {
            lang: this.context.language,
            removeOnUnmount: true
        };
    }

    componentDidMount() {
        this.getEvent();
    }

    getEvent = () => api.checkLogin()
        .then(() => {
            this.getTotalVideoLength();
            api.get('getParticipantCount', { eventRequestId: this.id, onlyActive: false })
                .then(({ participantCount }) => this.setState({ participantCount }));
            return api.get('getEvent', { eventRequestId: this.id })
        })
        .then(data => this.setState({ data: { ...this.state.data, ...data } }) || data);

    componentWillUnmount() {
        this.clearGetStatusInterval();
    }

    uploadFile = event => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 100 * 1024 * 1024) {
            this.setState({ modalText: this.props.getString('progress.maxAudioTrackSize'), showModal: true });
            event.target.value = null;
            return;
        }

        this.setState({uploading: true});
        api.upload('audioUpload', {
            eventRequestId: this.id,
            file: file,
            reCaptchaToken: this.state.gatherCaptcha
        }).then(data => {
            this.setState({data, uploading: false, gatherCaptcha: null});
            this.gatherCaptcha.reset();
        });
    };

    deleteFile = () => {
        this.setState({ uploading: true });
        api.post('audioDelete', {
            eventRequestId: this.id,
            eventPrivateKey: this.key,
            reCaptchaToken: this.state.gatherCaptcha
        }).then(data => {
            this.setState({data, uploading: false, gatherCaptcha: null});
            this.gatherCaptcha.reset();
        });
    };

    gatherVideo = (useAudioSync) => {
        this.setState({ data: { ...this.state.data, process: null, useAudioSync }});
        api.post('gatherVideo', {
            reCaptchaToken: this.state.gatherCaptcha,
            eventRequestId: this.id,
            eventPrivateKey: this.key,
            useAudioSync
        }).then(data => {
            this.setState({
                data: {
                    ...this.state.data,
                    ...data,
                },
                gatherCaptcha: null
            });
            this.gatherCaptcha.reset();
        });
    };

    getStatus = () =>
        api.get('getEventProgress', {
            eventRequestId: this.id
        }).then(status => this.setState({ data: {
            ...this.state.data,
            ...status
        } }));

    getTotalVideoLength = () => api
        .get('getTotalVideoLength', { eventRequestId: this.id })
        .then(result => this.setState({ totalVideoLength: result.totalVideoLength }));

    download = () =>  {
        window.open(
            `${config.apiUrl}/downloadResultVideo?token=${localStorage.getItem(storageAccessToken)}&eventPrivateKey=${this.key}&reCaptchaToken=${this.state.gatherCaptcha}`,
            '_blank'
        );

        this.setState({
            gatherCaptcha: null,
            showModal: false,
            data: {
                ...this.state.data,
                status: 'Done'
            }
        });
        this.gatherCaptcha.reset();
    };

    get previewUrl() {
        return `${config.apiUrl}/downloadResultPreviewVideo?token=${localStorage.getItem(storageAccessToken)}&eventPrivateKey=${this.key}#t=0.01`;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.processEndedWithError) {
            this.clearGetStatusInterval();
        }

        if (this.statusChangedTo(prevState, 'Editing')) {
            this.clearGetStatusInterval();
            this.setState({ canDownload: true, showPreview: true, data: { ...this.state.data, process: null } });
        }

        if (this.statusChangedTo(prevState, 'SocialMediaSearch')
            || this.statusChangedTo(prevState, 'VideoProcessing')
            || this.statusChangedTo(prevState, 'Waiting')
            || this.statusChangedTo(prevState, 'SpliceProcessing')
            || this.statusChangedTo(prevState, 'PreparePreview')) {
            this.setState({ canDownload: false, showPreview: false });
            this.getEvent();
            if (!this.getStatusInterval) {
                this.getStatusInterval = setInterval(this.getStatus, 1000);
            }
        }

        if (this.statusChangedTo(prevState, 'SpliceProcessing')) {
            this.getTotalVideoLength();
        }

        if (this.statusChangedTo(prevState, 'Done')) {
            this.setState({ canDownload: false, showPreview: true });
        }
    }

    clearGetStatusInterval = () => {
        if (this.getStatusInterval) {
            clearInterval(this.getStatusInterval);
            delete this.getStatusInterval;
        }
    };

    statusChangedTo = (prevState, status) =>
        prevState.data.status !== this.state.data.status && this.state.data.status === status;

    get DownloadButton() {
        const { getString } = this.props;
        const { canDownload, gatherCaptcha, data } = this.state;

        return canDownload && <Button
            disabled={!gatherCaptcha || data.process}
            width="239px"
            title={getString('progress.download')}
            onClick={() => this.setState({ showModal: true })}
        />
    }

    render() {
        const {
            previewLoading, data, canDownload, showPreview, showModal, uploading, gatherCaptcha,
            modalText, duration, totalVideoLength, participantCount
        } = this.state;
        const { getString, windowWidth } = this.props;
        const desktop = windowWidth > 1200;

        return (
            [<div className={s.container}>
                <div className={s.content}>
                    <PageHeader mobileTitle={getString('progress.createVideo')}>
                        {data.name}
                    </PageHeader>
                    {!showPreview && <EventPicture
                        type={data.eventType}
                        src={data.picture}
                        pictureFormat={data.pictureFormat}
                        hideForDesktop
                    />}
                    <div className={s.columns}>
                        <div className={s.left}>
                            <MobileHeader title={data.name} />
                            <div>
                                {getString('progress.filmersCount')}: <b>{participantCount}</b>
                            </div>
                            {this.videoLengthCalculated && <div>
                                {getString('progress.totalFilmedLength')}: <b>{formatDurationFromSeconds(totalVideoLength)}</b>
                            </div>}
                            <div>
                                {getString('form.hashTag')}: <b>{data.hashTag}</b>
                            </div>
                            <div>
                                {getString('form.description')}: <b>{data.description}</b>
                            </div>
                            <div className={s.separator} />
                            <div className={s.label}>
                                {getString('progress.audioTrack')}:
                            </div>
                            <div className={s.uploaded}>
                                {data.audioTrackName}
                                {uploading && <Loader className={s.loader} />}
                            </div>
                            {data.audioTrackName && <div className={s.warning}>
                                {getString('progress.audioTrackWarning')}
                            </div>}
                            <div className={s.load}>
                                <input
                                    type="file"
                                    accept="audio/*"
                                    ref={e => this.fileInput = e}
                                    style={{ display: 'none' }}
                                    onChange={this.uploadFile}
                                />
                                <Button disabled={!gatherCaptcha} width="178px" title={getString('progress.uploadTrack')} onClick={() => this.fileInput.click()} />
                                {data.audioTrackPath && <Button disabled={!gatherCaptcha} width="178px" title={getString('progress.deleteTrack')} onClick={this.deleteFile} />}
                                <small dangerouslySetInnerHTML={{__html: getString('progress.audioTrackSmallText')}} />
                            </div>
                            <ReCAPTCHA
                                ref={e => this.gatherCaptcha = e}
                                sitekey={config.recaptchaSiteKey}
                                onChange={gatherCaptcha => this.setState({ gatherCaptcha })}
                                language={this.context.language}
                            />
                            {this.inProgress && !this.processEndedWithError && <div className={s.bottomWarning}>
                                {getString('progress.bottomWarning')}
                            </div>}
                            <Process
                                status={data.status}
                                process={data.process}
                                totalVideoLength={totalVideoLength}
                            />
                            <div className={s.bottom}>
                                <div className={s.row}>
                                    <Button
                                        disabled={!gatherCaptcha}
                                        width="242x"
                                        title={getString('progress.gatherVideos')}
                                        onClick={() => this.gatherVideo(false)}
                                    />
                                    {data.useAudioSync || this.DownloadButton}
                                </div>
                                <div className={s.row}>
                                    <Button
                                        disabled={!gatherCaptcha}
                                        maxWidth="242px"
                                        title={getString('progress.gatherVideosWithSync')}
                                        onClick={() => this.gatherVideo(true)}
                                    />
                                    {data.useAudioSync && this.DownloadButton}
                                </div>
                            </div>
                        </div>
                        <div className={s.right}>
                            <span>
                                {previewLoading && <Loader className={s.previewLoader} />}
                                {!showPreview && desktop && <img className={s.videoPlaceholder} src={videoPlaceholder} />}
                                {showPreview && <Player
                                    fluid={false}
                                    width={desktop ? 420 : windowWidth}
                                    onDurationChange={e => this.setState({ duration: e.target.duration * 1000 })}
                                    playsInline
                                    canplaythrough
                                >
                                    <source src={this.previewUrl} type="video/mp4" />
                                </Player>}
                            </span>
                            {desktop && canDownload && <Edit
                                captcha={gatherCaptcha}
                                duration={duration}
                                onChange={data => {
                                    this.setState({ gatherCaptcha: null, data });
                                    this.gatherCaptcha.reset();
                                }}
                                privateKey={this.key}
                            />}
                        </div>
                    </div>
                </div>
            </div>,
                <Modal error={modalText} download={this.download} isOpen={showModal} close={() => this.setState({ showModal: false, modalText: null })} />,
                <Footer />]
        );
    }
}

Progress.contextType = LocalizationContext;

export default windowSize(localize(Progress));
