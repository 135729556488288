import React from 'react';
import { FastField as Base, getIn} from "formik";

class FastField extends React.Component {
    shouldUpdate = (nextProps, props) => {
        return getIn(props.formik.values, props.name) !==
            getIn(nextProps.formik.values, props.name) ||
            getIn(props.formik.errors, props.name) !==
            getIn(nextProps.formik.errors, props.name) ||
            getIn(props.formik.touched, props.name) !==
            getIn(nextProps.formik.touched, props.name) ||
            Object.keys(props).length !== Object.keys(nextProps).length ||
            props.formik.isSubmitting !== nextProps.formik.isSubmitting
            || nextProps.title !== props.title;
    };

    render() {
        return <Base {...this.props} shouldUpdate={this.shouldUpdate} />;
    }
}

export default FastField;
