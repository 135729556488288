import Celebration from './Celebration.svg';
import CelebrationIcon from './Celebration_icon.svg';
import Conference from './Conference.svg';
import ConferenceIcon from './Conference_icon.svg';
import Disco from './Disco.svg';
import DiscoIcon from './Disco_icon.svg';

const images = {
    Conference,
    Celebration,
    Disco
};

const icons = {
    Celebration: CelebrationIcon,
    Conference: ConferenceIcon,
    Disco: DiscoIcon
};

export { images, icons };