import React from 'react';
import cx from 'classnames';
import s from './Loader.module.scss';

const Loader = ({ className }) => (
    <div className={cx(s.container, className)}>
        <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <symbol id="s--circle">
                <circle r="10" cx="20" cy="20"/>
            </symbol>

            <g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
                <g className={s.circle}>
                    <use xlinkHref="#s--circle" className="u--circle"/>
                </g>
            </g>
        </svg>
    </div>
);

export default Loader;
