import React from 'react';
import s from './Authentication.module.scss';
import localize from "../../../../Localizations/Localize";
import Register from "./Register/Register";
import Modal from "./Modal/Modal";
import {AuthorizationContext} from "../../../../Authorization/AuthorizationContext";
import SignIn from "./SignIn/SignIn";
import RequestPasswordReset from "./RequestPasswordReset/RequestPasswordReset";
import {withRouter} from "react-router-dom";
import ResetPassword from "./ResetPassword/ResetPassword";
import {storageEmail} from "../../../../constants";
import api from "../../../api";

class Authentication extends React.Component {
    state = {
        modal: false,
        code: null
    };

    setModal = name => this.setState({ modal: name, code: null });

    componentDidMount() {
        var { pathname } = this.props.location;
        var verifyEmailPath = '/verifyEmail/';
        if (pathname.indexOf(verifyEmailPath) !== -1) {
            this.setState({ modal: 'signIn', code: pathname.replace(verifyEmailPath, '')});
        }

        var resetPasswordPath = '/resetPassword/';
        if (pathname.indexOf(resetPasswordPath) !== -1) {
            this.setState({ modal: 'resetPassword', code: pathname.replace(resetPasswordPath, '')});
        }

    }

    render() {
        const { role } = this.context;
        const { getString } = this.props;
        const { modal, code } = this.state;

        return (
            <div className={s.container}>
                {!role && <p className={s.red} onClick={() => this.setModal('register')}>
                    {getString('authorization.register')}
                </p>}
                {!role && <p onClick={() => this.setModal('signIn')}>
                    {getString('authorization.signIn')}
                </p>}
                {role && <p style={{ textTransform: 'unset', cursor: 'default' }}>
                    {localStorage.getItem(storageEmail)}
                </p>}
                {role && <p onClick={api.logout}>
                    {getString('authorization.signOut')}
                </p>}
                <Modal
                    opened={modal}
                    close={() => this.setModal(false)}
                    title={getString(`authorization.modalTitle.${modal}`)}
                >
                    {modal === 'register' && <Register setModal={this.setModal} />}
                    {modal === 'signIn' && <SignIn setModal={this.setModal} code={code} />}
                    {modal === 'requestPasswordReset' && <RequestPasswordReset setModal={this.setModal} />}
                    {modal === 'resetPassword' && <ResetPassword setModal={this.setModal} code={code} />}
                </Modal>
            </div>
        );
    }
}

Authentication.contextType = AuthorizationContext;

export default withRouter(localize(Authentication));
