import React from 'react';
import { config, Spring } from 'react-spring';
import s from '../Landing.module.scss';
import item1 from '../Images/hiw1.svg';
import item2 from '../Images/hiw2.svg';
import item3 from '../Images/hiw3.svg';
import item4 from '../Images/hiw4.svg';
import Button from '../../../Components/Button/Button';
import handleViewport from 'react-in-viewport';
import localize from '../../../Localizations/Localize';
import windowSize from "react-window-size";
import appStore from '../../../Components/Footer/appStore.svg';
import gplay from "../../../Components/Footer/gplay.svg";
import youtube from '../../../Components/Footer/youtube.svg';

const items = [
    {id: 1, img: item1},
    {id: 2, img: item2},
    {id: 3, img: item3},
    {id: 4, img: item4}
];

const FilmersBlock = ({inViewport, innerRef, getString, windowWidth}) => {
    const mobile = windowWidth < 834;
    return (
        <div className={s.block_3} id="filmers" ref={innerRef}>
            <div className={s.content}>
                <h2 dangerouslySetInnerHTML={{__html: getString('landing.block3title')}} />
                <div className={s.features}>
                    {[...new Array(3)].map((c, i) =>

                        <div className={s.feature}>
                            <h3>{getString('landing.block3feature' + (i + 1))}</h3>
                            <p dangerouslySetInnerHTML={{__html: getString('landing.block3feature' + (i + 1) + 'content')}} />
                        </div>
                    )}
                </div>
                <div className={s.items}>
                    {inViewport && items.map((item, index) =>
                        <Spring
                            to={{transform: 'translate3d(0,0,0)', opacity: 1}}
                            from={{transform: `translate3d(-${140 * index + 90}px,0,0)`, opacity: 0}}
                            config={config.slow}
                            delay={1000 * index}
                        >
                            {props => <div className={s.item} style={props}>
                                <div className={s.image} style={{opacity: props.opacity}}>
                                    <img src={item.img} />
                                </div>
                                <span dangerouslySetInnerHTML={{__html: getString(`landing.block3item` + item.id)}} />
                            </div>}
                        </Spring>)}
                </div>
                {mobile ? <React.Fragment>
                    <a style={{ marginTop: '50px' }} target="_blank" href={getString('global.appleAppUrl')}>
                        <img src={appStore} />
                    </a>
                    <a style={{ marginTop: '14px' }} target="_blank" href="https://play.google.com/store/apps/details?id=com.crowdfilmingforyou">
                        <img style={{ width: '201px' }} src={gplay} />
                    </a>
                    <a style={{ marginTop: '14px' }} target="_blank" href="https://www.youtube.com/channel/UCXNkaczMvvrx5gNS_bXEqtA">
                        <img style={{ width: '201px' }} src={youtube} />
                    </a>
                </React.Fragment>: <Button
                    title={getString('global.downloadApp')}
                    className={s.button}
                    onClick={() => document.getElementById('mobileApp').scrollIntoView({behavior: 'smooth'})}
                />}
            </div>
        </div>
    );
};

export default windowSize(handleViewport(localize(FilmersBlock), {rootMargin: '-1.0px'}));
