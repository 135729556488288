import React, { Component } from 'react';
import Landing from '../Landing/Landing';
import Event from '../Event/Event';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import Header from '../Landing/Header/Header';
import EventForm from "../EventForm/EventForm";
import Progress from "../Progress/Progress";
import MyPage from "../MyPage/MyPage";
import Localization from '../../Localizations/LocalizationContext';
import EmailSent from "../EmailSent/EmailSent";

class App extends Component {
    render() {
        const { location } = this.props;
        return (
            <Localization>
                <Header
                    white={location.pathname === '/' ? null : true}
                    hideCreateButton={location.pathname === '/create'}
                />
                <Switch>
                    <Route path="/emailSent/:type" component={EmailSent}/>
                    <Route path="/verifyEmail/:code" component={Landing}/>
                    <Route path="/resetPassword/:code" component={Landing}/>
                    <Route path="/event/:id" component={Event}/>
                    {/* <Route path="/create" component={EventForm}/> */}
                    <Route path="/progress/:id" component={Progress}/>
                    <Route path="/myPage/:id" component={MyPage}/>
                    <Route path="/edit/:id" component={EventForm}/>
                    <Route path="/" exact component={Landing}/>
                    <Redirect to="/" />
                </Switch>
            </Localization>
        );
    }
}

export default withRouter(App);
