import React from 'react';
import s from './Item.module.scss';
import cx from "classnames";

const Item = ({ current, eventType, title, setValue }) => (
    <li
        className={cx(s.container, eventType === current && s.active)}
        onClick={() => setValue(eventType)}
    >
        {title}
    </li>
);

export default Item;
