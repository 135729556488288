import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import s from './Header.module.scss';
import Button from '../../../Components/Button/Button';
import logoWhite from '../../../Resources/Images/logo_white.svg';
import logo from '../../../Resources/Images/logo.svg';
import {Link, withRouter} from "react-router-dom";
import localize from '../../../Localizations/Localize';
import { languages, LocalizationContext } from "../../../Localizations/LocalizationContext";
import { moveTo } from '../../../Helpers/DomHelper';
import windowSize from "react-window-size";
import {headerItems} from "../../../constants";
import Authentication from "./Authentication/Authentication";

class Header extends React.Component {
    state = {
        isWhite: false,
        activeBlock: 0,
        landingInitialized: false
    };

    componentDidMount() {
        this.initScroll();
    }

    componentDidUpdate(prevProps) {
        this.initScroll();

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ showOnMobile: false });
            const landing = document.getElementById('landing');
            if (landing) {
                landing.dispatchEvent(new Event('scroll'))
            }
        }
    }

    initScroll = () => {
        const landing = document.getElementById('landing');
        if (landing) {
            if (this.state.landingInitialized) {
                return;
            }

            this.updateActiveBlock();
            landing.addEventListener('scroll', this.updateActiveBlock);
        }

        this.state.landingInitialized = landing;
    };

    updateActiveBlock = () => {
        const landing = document.getElementById('landing');
        const activeBlock = _.sortBy(headerItems, e => Math.abs(document.getElementById(e).getBoundingClientRect().top))[0];
        if (activeBlock) {
            if (this.state.activeBlock !== activeBlock) {
                this.setState({activeBlock});

                if (headerItems.indexOf(activeBlock) > 1) {
                    this.setState({isWhite: true});
                    landing.style = 'scroll-snap-type: none;';
                } else {
                    this.setState({isWhite: false});
                    landing.removeAttribute('style');
                }
            }
        }
    };

    getHeadItem = id =>
        <p onClick={() => {
            if (this.props.location.pathname !== '/') {
                this.props.history.push('');
            }

            setTimeout(() => moveTo(id))
        }} className={this.state.activeBlock === id && s.active}>{this.props.getString(`global.${id}`)}</p>;

    render() {
        const {white, getString, hideCreateButton, windowWidth} = this.props;
        const {isWhite, showOnMobile} = this.state;
        const whiteOrIsWhite = white || isWhite;
        const isMobile = windowWidth < 1201;
        return (
            [<header className={cx(s.header, {
                [s.white]:whiteOrIsWhite,
                [s.showOnMobile]: showOnMobile,
                [s.isMobile]: isMobile
            })}>
                <div className={s.left}>
                    <Link to="/">
                        {whiteOrIsWhite && <img src={logoWhite} className={s.logo} />}
                        <img src={logo} className={s.mobileLogo} />
                    </Link>
                    {headerItems.map(this.getHeadItem)}
                </div>
                <div className={s.right}>
                    {/* <Authentication />
                    {hideCreateButton || <Link to="/create">
                        <Button title={getString('global.createProject')} size="small" />
                    </Link>} */}
                    <ul className={s.languages}>
                        {Object.keys(languages).map(i =>
                            <li onClick={() => this.context.changeLanguage(i)}
                                className={this.context.language === i && s.active}>
                                {languages[i]}
                            </li>
                        )}
                    </ul>
                </div>
                {showOnMobile && <svg className={s.close} onClick={() => this.setState({ showOnMobile: !showOnMobile })} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L20 20" stroke="white" strokeWidth="2"/>
                    <path d="M20 1L0.999999 20" stroke="white" strokeWidth="2"/>
                </svg>}
            </header>,
                <svg
                    onClick={() => this.setState({ showOnMobile: !showOnMobile })}
                    className={cx(s.menu, isMobile && s.isMobile)}
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="22" cy="22" r="22" fill="#E31E24"/>
                    <path d="M14 16H30" stroke="white" strokeWidth="2"/>
                    <path d="M14 22.5H30" stroke="white" strokeWidth="2"/>
                    <path d="M14 28.5H22" stroke="white" strokeWidth="2"/>
                </svg>]
        );
    }
}


Header.contextType = LocalizationContext;

export default windowSize(withRouter(localize(Header)));

