import React from 'react';
import appStore from '../Images/appstore.svg';
import gPlay from '../Images/gplay.svg';
import youtube from '../Images/youtube.svg';
import s from '../Landing.module.scss';
import localize from "../../../Localizations/Localize";

const MobileBlock = ({ getString }) => {
    return (
        <div className={s.block_mobile} id="mobileApp">
            <div className={s.inner}>
                <h2>{getString('landing.blockMobileTitle')}</h2>
                <p>{getString('landing.blockMobileContent')}</p>
                <div className={s.buttons}>
                    <a target="_blank" href={getString('global.appleAppUrl')}><img src={appStore} /></a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.crowdfilmingforyou"><img src={gPlay} /></a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCXNkaczMvvrx5gNS_bXEqtA"><img src={youtube} /></a>
                </div>
            </div>
        </div>
    );
};

export default localize(MobileBlock);
