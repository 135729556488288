import React from 'react';
import s from './SignIn.module.scss';
import Input from "../../../../../Components/Input/Input";
import * as yup from "yup";
import api from "../../../../api";
import {withRouter} from "react-router-dom";
import BaseForm from "../BaseForm/BaseForm";
import localize from "../../../../../Localizations/Localize";

class SignIn extends React.Component {
    componentDidMount() {
        const { code } = this.props;
        if (code) {
            api.post('user/verifyEmail', { code })
                .catch(response => response.json().then(error => this.form.setState({ error })))
        }
    }

    onSubmit = data => api.userLogin(data)
        .then(() => this.props.setModal(false));

    render() {
        const { setModal, getString } = this.props;

        return (
            <BaseForm
                close={() => setModal(false)}
                onSubmit={this.onSubmit}
                validationSchema={yup.object().shape({
                    email: yup.string().email().required(),
                    password: yup.string().required(),
                    reCaptchaToken: yup.string().required()
                })}
                buttons={{
                    right: <span
                        onClick={() => setModal('requestPasswordReset')}
                        className={s.right}
                    >{getString('authorization.forgotPassword')}</span>
                }}
                ref={e => this.form = e}
            >
                <Input required name="email" title="E-mail"/>
                <Input type="password" required name="password" title={getString('authorization.password')} />
            </BaseForm>
        );
    }
}

export default localize(withRouter(SignIn));
