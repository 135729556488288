import React from 'react';
import s from './Filter.module.scss';
import localize from "../../../Localizations/Localize";
import Item from "./Item/Item";
import windowSize from 'react-window-size';
import Select from "react-select";

const Filter = ({ getString, eventType, eventTypes, onChange, windowWidth }) => (
    windowWidth > 833 ? <div className={s.container}>
        <span>{getString('event.type')}:</span>
        <ul>
            <Item
                eventType={null}
                title={getString('event.eventTypes.all')}
                current={eventType}
                setValue={onChange}
            />
            {eventTypes && eventTypes.map(e => <Item
                eventType={e}
                title={getString(`event.eventTypes.${e}`)}
                current={eventType}
                setValue={onChange}
            />)}
        </ul>
    </div> : <Select
        onChange={e => onChange(e.value)}
        value={{ value: eventType, label: eventType ? getString(`event.eventTypes.${eventType}`) : getString('event.eventTypes.all') }}
        className={s.select}
        options={eventTypes && eventTypes.map(e => (
            eventType === e
                ? { value: null, label: getString('event.eventTypes.all') }
                : { value: e, label: getString(`event.eventTypes.${e}`) })
        )}
        styles={{ indicatorSeparator: () => ({ display: 'none' })}}
    />
);

export default windowSize(localize(Filter));
