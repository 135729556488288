import React from 'react';
import api from '../api';
import s from './MyPage.module.scss';
import Footer from "../../Components/Footer/Footer";
import Button from "../../Components/Button/Button";
import {Link} from "react-router-dom";
import {formatDate} from "../../Helpers/FormatHelper";
import localize from '../../Localizations/Localize';
import EventPicture from "../../Components/EventPicture/EventPicture";
import qs from "query-string";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import {currencies, getOption} from "../EventForm/DtoHelper";
import PageHeader from "../../Components/PageHeader/PageHeader";

class MyPage extends React.Component {
    state = {
        data: {}
    };

    get key() {
        const { key } = qs.parse(this.props.location.search);
        return key;
    }

    componentDidMount() {
        api.checkLogin()
            .then(() => api.get('getEvent', { eventRequestId: this.props.match.params.id }))
            .then(data => this.setState({ data }) || data);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data.id !== this.state.data.id) {
            if (this.state.data.endUnixMilliseconds < new Date()) {
                this.props.history.push(`/progress/${this.state.data.id}?key=${this.key}`);
            }
        }
    }

    render() {
        const { data } = this.state;
        const { match, getString } = this.props;

        return (
            [<div className={s.container}>
                <div className={s.content}>
                    <PageHeader mobileTitle={getString('global.event')}>
                        {data.name}
                    </PageHeader>
                    <div className={s.columns}>
                        <div className={s.left}>
                            <EventPicture
                                src={data.picture}
                                pictureFormat={data.pictureFormat}
                                type={data.eventType}
                            />
                            <table cellSpacing={0} cellPadding={0}>
                                <MobileHeader title={data.name} />
                                {data.site && <tr>
                                    <td>{getString('form.site')}:</td>
                                    <td>{data.site}</td>
                                </tr>}
                                <tr>
                                    <td>{getString('form.eventType')}:</td>
                                    <td>{getString(`form.eventTypes.${data.eventType}`)}</td>
                                </tr>
                                <tr>
                                    <td>{getString('form.privacy')}:</td>
                                    <td>{getString(`form.privacyTypes.${data.privacy ? 'private' : 'forAll'}`)}</td>
                                </tr>
                                <tr>
                                    <td>{getString('form.address')}:</td>
                                    <td>{data.location}</td>
                                </tr>
                                <tr>
                                    <td>{getString('form.date')}:</td>
                                    <td>{formatDate(data.startUnixMilliseconds)} - {formatDate(data.endUnixMilliseconds)}</td>
                                </tr>
                                <tr>
                                    <td>{getString('form.hashTag')}:</td>
                                    <td>#{data.hashTag}</td>
                                </tr>
                                <tr>
                                    <td>{getString('form.price')}:</td>
                                    <td>{data.price} {getOption(data.currency, currencies).label}</td>
                                </tr>
                            </table>
                            {data.description && <div>
                                <div className={s.label}>
                                    {getString('form.description')}:
                                </div>
                                <div className={s.value}>
                                    {data.description}
                                </div>
                            </div>}
                            <Link to={`/edit/${match.params.id}?key=${this.key}`}>
                                <Button width="143px" title={getString('form.change')} />
                            </Link>
                        </div>
                        <div className={s.right} />
                    </div>
                </div>
            </div>,
            <Footer />]
        );
    }
}

export default localize(MyPage);
