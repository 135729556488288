import React from 'react';
import Input from "../../../../../Components/Input/Input";
import * as yup from "yup";
import api from "../../../../api";
import {withRouter} from "react-router-dom";
import BaseForm from "../BaseForm/BaseForm";
import localize from "../../../../../Localizations/Localize";

class RequestPasswordReset extends React.Component {
    onSubmit = data => api.post('user/requestPasswordReset', data)
        .then(() => {
            this.props.history.push('/emailSent/resetPassword');
            this.props.setModal(false);
        });

    render() {
        const { setModal, getString } = this.props;

        return (
            <BaseForm
                close={() => setModal('signIn')}
                onSubmit={this.onSubmit}
                validationSchema={yup.object().shape({
                    email: yup.string().email().required(),
                    reCaptchaToken: yup.string().required()
                })}
                subtitle={getString('authorization.requestPasswordResetSubtitle')}
                buttons={{
                    submitTitle: getString('form.send'),
                    cancelTitle: getString('form.back')
                }}
            >
                <Input required name="email" title="E-mail"/>
            </BaseForm>
        );
    }
}

export default localize(withRouter(RequestPasswordReset));
