import React from 'react';
import s from './Item.module.scss';
import {withRouter} from "react-router-dom";
import EventPicture from "../../../Components/EventPicture/EventPicture";
import {getDataUrl, maxLength} from "../../../Helpers/FormatHelper";

const EventItem = ({ id, src, type, title, description, time, count, history, icon,
    pictureFormat, iconFormat
}) => {
    return (
        <div className={s.container} onClick={() => history.push(`/event/${id}`)}>
            <EventPicture src={src} pictureFormat={pictureFormat} type={type} small />
            <div className={s.content}>
                <img src={getDataUrl(icon, iconFormat)} className={s.type} />
                <p className={s.title}>{title}</p>
                <p className={s.description}>{maxLength(description, 100)}</p>
                <div className={s.info}>
                    <span>{time}</span>
                    <span className={s.users}>{count}</span>
                </div>
            </div>
        </div>
    );
};

export default withRouter(EventItem);
