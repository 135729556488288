import React from 'react';
import s from './Edit.module.scss';
import localize from "../../../Localizations/Localize";
import cutIcon from './cut.svg';
import removeIcon from './remove.svg';
import Input from "./Input/Input";
import {FieldArray, Form, Formik} from "formik";
import Button from "../../../Components/Button/Button";
import getValidationSchema from './validationSchema';
import api from "../../api";
import moment from 'moment';

class Edit extends React.Component {
    state = {
        showIntervals: false
    };

    onSubmit = data => {
        api.post('cutVideo', {
            videoIntervals: data.intervals.map((e, i) => ({
                startMilliseconds: moment.duration(e.from).asMilliseconds(),
                endMilliseconds: moment.duration(e.to).asMilliseconds(),
                order: i
            })),
            eventPrivateKey: this.props.privateKey,
            reCaptchaToken: this.props.captcha
        }).then(this.props.onChange);
    };

    render() {
        const {getString, duration, captcha} = this.props;
        const { showIntervals } = this.state;
        return (
            <div className={s.container}>
                <Formik onSubmit={this.onSubmit} validationSchema={getValidationSchema(duration)}
                        initialValues={{ intervals: [{ from: '', to: '' }] }}
                        render={({values}) => (
                            <Form>
                                <FieldArray
                                    name="intervals"
                                    render={arrayHelpers => (<React.Fragment>
                                        <div className={s.title} onClick={() => this.setState({ showIntervals: !showIntervals })}>
                                            <img src={cutIcon}/>
                                            {getString('progress.edit.title')}
                                        </div>
                                        {showIntervals && <React.Fragment>
                                            <div className={s.description}>
                                                {getString('progress.edit.description')}
                                            </div>
                                            <div className={s.intervals}>
                                                {values.intervals.map((e, i) => <div className={s.interval}>
                                                    <span>{getString('progress.edit.from')}</span>
                                                    <Input name={`intervals.${i}.from`}/>
                                                    <span>{getString('progress.edit.to')}</span>
                                                    <Input name={`intervals.${i}.to`}/>
                                                    <img src={removeIcon} onClick={() => arrayHelpers.remove(i)}/>
                                                </div>)}
                                                <p className={s.add} onClick={() => arrayHelpers.push({})}>
                                                    {getString('progress.edit.addInterval')}
                                                </p>
                                                {values.intervals && values.intervals.length > 0 &&
                                                <Button disabled={!captcha} htmlType="submit" className={s.button}
                                                        title={getString('progress.edit.button')}/>}
                                            </div>
                                        </React.Fragment>
                                        }
                                    </React.Fragment>)}
                                />
                            </Form>
                        )}/>
            </div>
        );
    }
}

export default localize(Edit);
