import React from 'react';
import s from './EmailSent.module.scss';
import icon from './emailSent.svg';
import localize from "../../Localizations/Localize";

const EmailSent = ({ match, getString }) => (
    <div className={s.container}>
        <img src={icon} />
        {getString(`emailSent.${match.params.type}`)}
    </div>
);

export default localize(EmailSent);
