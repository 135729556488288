import React from 'react';
import logo from './Images/logo.svg';
import howItWorks from './Images/2.svg';
import s from './Landing.module.scss';
import Button from '../../Components/Button/Button';
import MobileBlock from './Blocks/Mobile';
import Footer from '../../Components/Footer/Footer';
import Events from '../Events/Events';
import banner from '../../Resources/fast_start.mp4';
import banner_webm from '../../Resources/fast_start.webm';
import FilmersBlock from './Blocks/Filmers';
import OrganizationsBlock from './Blocks/Organizations';
import localize from '../../Localizations/Localize';
import HowToUse from "./Blocks/HowToUse";

const Landing = ({ getString }) => {
    return (
        <div className={s.container} id="landing">
            <div className={s.video}>
                <video preload="auto" loop autoPlay muted playsInline>
                    <source src={banner} type="video/mp4"/>
                    <source src={banner_webm} type="video/webm" />
                </video>
            </div>
            <div className={s.block_1}>
                <img src={logo} className={s.logo}/>
                <p className={s.slogan} dangerouslySetInnerHTML={{ __html: getString('landing.slogan')}}/>
            </div>
            <div className={s.block_2} id="about">
                <div className={s.inner}>
                    <div className={s.content}>
                        <h2>{getString('landing.block2title')}</h2>
                        <div dangerouslySetInnerHTML={{ __html: getString('landing.block2content')}} />
                        <div className={s.buttons}>
                            <Button
                                title={getString('landing.wantCreateEvent')}
                                onClick={() => document.getElementById('forOrgs').scrollIntoView({ behavior: 'smooth' })}
                            />
                            <Button
                                title={getString('landing.wantFilming')}
                                type="none"
                                onClick={() => document.getElementById('filmers').scrollIntoView({ behavior: 'smooth' })}
                            />
                        </div>
                    </div>
                    <img src={howItWorks}/>
                </div>
            </div>
            <FilmersBlock />
            <OrganizationsBlock/>
            <Events/>
            <MobileBlock/>
            <HowToUse />
            <Footer/>
            <div className={s.arrow_down}/>
        </div>
    );
};

export default localize(Landing);
