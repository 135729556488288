import React from 'react';
import s from './EventCard.module.scss';
import PageHeader from "../../../Components/PageHeader/PageHeader";
import api from "../../api";
import localize from "../../../Localizations/Localize";
import {formatDate, timeLeft} from "../../../Helpers/FormatHelper";
import EventPicture from "../../../Components/EventPicture/EventPicture";
import {LocalizationContext} from "../../../Localizations/LocalizationContext";
import MobileHeader from "../../../Components/MobileHeader/MobileHeader";
import {currencies, getOption} from "../../EventForm/DtoHelper";

class EventCard extends React.Component {
    state = {
        event: {}
    };

    componentDidMount() {
        api.checkLogin()
            .then(() => api.get('getEvent', { eventRequestId: this.props.id }))
            .then(event => this.setState({ event }))
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            api.checkLogin()
                .then(() => api.get('getEvent', { eventRequestId: this.props.id }))
                .then(event => this.setState(
                    { event },
                ));
            this.container.scrollIntoView({behavior: 'smooth'})
        }
    }


    render() {
        const { event } = this.state;
        const { getString } = this.props;
        const { language } = this.context;

        var inProgress = event.startUnixMilliseconds - new Date() <= 0;
        return (
            <div className={s.container} ref={e => this.container = e}>
                <EventPicture
                    src={event.picture}
                    type={event.eventType}
                    big
                    icon={event.icon}
                    pictureFormat={event.pictureFormat}
                    iconFormat={event.iconFormat}
                />
                <PageHeader mobileTitle={getString('global.event')}>
                    {event.name}
                </PageHeader>
                <MobileHeader title={event.name} className={s.title} />
                <span className={s.subtitle}>
                    {!inProgress && getString('event.willStartIn') + ': '}{timeLeft(event.startUnixMilliseconds, language)}
                </span>
                <span className={s.count}>
                    {event.participantCount}
                </span>
                <h2>
                    {getString(`form.eventTypes.${event.eventType}`)}
                    {!!event.price && <React.Fragment><span>|</span>{getString('event.entrance')}: {event.price} {getOption(event.currency, currencies).label}</React.Fragment>}
                    {event.site && <React.Fragment><span>|</span>{event.site}</React.Fragment>}
                    <span>|</span><span className={s.red}>#{event.hashTag}</span>
                </h2>
                {inProgress && <p>
                    {getString('event.dateAndTime')}: {getString('event.from')} {formatDate(event.startUnixMilliseconds)} {getString('event.to')} {formatDate(event.endUnixMilliseconds)}
                </p>}
                <p className={s.description}>
                    {event.description}
                </p>
                <div className={s.location}>
                    {event.location}
                </div>
            </div>
        );
    }
}

EventCard.contextType = LocalizationContext;

export default localize(EventCard);
