import appSlide3 from './Images/app/slide3.png';
import appSlide4 from './Images/app/slide4.png';
import appSlide5 from './Images/app/slide5.png';
import appSlide7 from './Images/app/slide7.png';
import appSlide8 from './Images/app/slide8.png';
import appSlide9 from './Images/app/slide9.png';
import appSlide11 from './Images/app/slide11.png';
import appSlide13 from './Images/app/slide13.png';
import appSlide15 from './Images/app/slide15.png';
import appSlide18 from './Images/app/slide18.png';

import webSlide2 from './Images/web/slide2.png';
import webSlide3 from './Images/web/slide3.png';
import webSlide4 from './Images/web/slide4.png';
import webSlide6 from './Images/web/slide6.png';
import webSlide13 from './Images/web/slide13.png';
import webSlide16 from './Images/web/slide16.png';
import webSlide17 from './Images/web/slide17.png';
import webSlide18 from './Images/web/slide18.png';

import webSlideMobile2 from './Images/web/mobile/slide2.png';
import webSlideMobile3 from './Images/web/mobile/slide3.png';
import webSlideMobile4 from './Images/web/mobile/slide4.png';
import webSlideMobile5 from './Images/web/mobile/slide5.png';
import webSlideMobile6 from './Images/web/mobile/slide6.png';
import webSlideMobile13 from './Images/web/mobile/slide13.png';
import webSlideMobile16 from './Images/web/mobile/slide16.png';
import webSlideMobile17 from './Images/web/mobile/slide17.png';
import webSlideMobile18 from './Images/web/mobile/slide18.png';

export default {
    app: { 3: appSlide3, 4: appSlide4, 5: appSlide5, 7: appSlide7, 8: appSlide8, 9: appSlide9,
        10: appSlide9, 11: appSlide11, 13: appSlide13, 15: appSlide15, 18: appSlide18 },
    web: { 2: webSlide2, 3: webSlide3, 4: webSlide4, 5: webSlide4, 6: webSlide6, 13: webSlide13,
        16: webSlide16, 17: webSlide17, 18: webSlide18,
    mobile: {
        2: webSlideMobile2, 3: webSlideMobile3, 4: webSlideMobile4, 5: webSlideMobile5,
        6: webSlideMobile6, 13: webSlideMobile13, 16: webSlideMobile16,
        17: webSlideMobile17, 18: webSlideMobile18
    }}
}