import React from 'react';
import s from './Process.module.scss';
import localize from "../../../Localizations/Localize";
import moment from "moment";
import cx from 'classnames';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

const statuses = ['Search', 'Downloading', 'Processing', 'Splitting', 'Merging', 'PreparePreview'];

const getStage = (status, process) => {
    switch (status) {
        case 'SocialMediaSearch':
            return (process && process.status === 'Downloading') ? 1 : 0;
        case 'VideoProcessing':
            return 2;
        case 'SpliceProcessing':
            return (process && process.status === 'Merging') ? 4 : 3;
        case 'PreparePreview':
            return 5;
        default:
            return 0;
    }
};

const Stage = localize(({ process, index, stage, getString }) => {
    let message = getString(`progress.${statuses[index]}`);
    if (index < stage) {
        message = `${message}. ${getString('progress.Done')}`;
    }
    const current = index === stage;

    return <div className={cx(s.stage, current && s.current)}>
        {message}{current && [
        ` ${(process.progress * 100).toFixed(0)}%`,
            <CircularProgressbar
                value={process.progress * 100}
                strokeWidth={12}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    trailColor: "#E5E5E5",
                    pathColor: "#E31E24",
                    rotation: 0.5
                })}
            />
        ]}
    </div>}
);

const Process = ({ status, process, totalVideoLength, getString }) => {
    const stage = getStage(status, process);
    if (stage === 5){
        process = { progress: 0.5 };
    }
    let error;

    if (process && process.status === 'Error') {
        if (status === 'SpliceProcessing' && moment.duration(totalVideoLength).asSeconds() < 1) {
            error = getString('progress.noVideoFound');
        } else {
            error = getString('progress.servicesError');
        }
    }

    return <div className={cx(s.container, error && s.error)}>
        {error ||
            (process && [...new Array(6)].map((_, index) =>
                <Stage
                    process={process}
                    index={index}
                    stage={stage}
                />
            ))
        }
    </div>;
};

export default localize(Process);