import React from 'react';
import qs from 'query-string';
import s from './EventForm.module.scss';
import Footer from "../../Components/Footer/Footer";
import Modal from "./Modal/Modal";
import PageHeader from "../../Components/PageHeader/PageHeader";
import {Formik} from "formik";
import Form from "./Form/Form";
import validationSchema from './validationSchema';
import api from '../api';
import {dtoToFormData, formDataToDto} from "./DtoHelper";
import localize from '../../Localizations/Localize';
import {LocalizationContext} from "../../Localizations/LocalizationContext";

class EventForm extends React.Component {
    state = {
        showModal: false
    };

    componentDidMount() {
        this.initForm();
    }

    componentDidUpdate(prevProps) {
        if (this.id !== prevProps.match.params.id) {
            this.initForm();
        }
    }

    initForm = () => {
        if (this.id) {
            api.checkLogin()
                .then(() => api.get('getEvent', { eventRequestId: this.id }))
                .then(data => {
                    if (data.endUnixMilliseconds < new Date()) {
                        this.props.history.push(`/progress/${data.id}?key=${this.key}`);
                    }
                    this.setState({ data: dtoToFormData(data) })
                });
        } else {
            if (this.state.data) {
                this.setState({data: null, reset: true}, () => this.setState({reset: false}));
            }
            api.checkLogin();
        }
    };

    get id() {
        return this.props.match.params.id;
    }

    get key() {
        const { key } = qs.parse(this.props.location.search);
        return key;
    }

    onSubmit = data => {
        data.locale = this.context.language;
        if (this.id) {
            data.eventPrivateKey = this.key;
            api.post('updateEventRequest', formDataToDto(data))
                .then(() => this.props.history.push(`/myPage/${this.id}?key=${this.key}`))
                .catch(this.onSubmitError)
                .then(this.onCallEnd);
        } else {
            api.post('addEventRequest', formDataToDto(data))
                .then(() => this.setState({showModal: true}))
                .catch(this.onSubmitError)
                .then(this.onCallEnd);
        }
    };

    onSubmitError = (response) => {
        this.formik.setState({ values: {
                ...this.formik.state.values,
                reCaptchaToken: null
        }});
        response.json().then(error => this.setState({ error }));
    };

    onCallEnd = () => this.formik && this.formik.setSubmitting(false);

    onModalClose = () => {
        this.setState({ showModal: false});

        const { error, data } = this.state;
        if (!error) {
            this.props.history.push(
                (data && data.id) ? `/myPage/${data.id}` : '/'
            );
        } else {
            this.setState({ error: null, modalTitle: null });
        }
    };

    render() {
        const { data, reset, error, showModal, modalTitle } = this.state;

        if ((this.id && !data) || reset) {
            return null;
        }

        return (
            [<div className={s.container}>
                <div className={s.content}>
                    <PageHeader>
                        {this.props.getString(`form.${this.id ? 'edit': 'create'}Event`)}
                    </PageHeader>
                    <Formik
                        ref={e => this.formik = e}
                        initialValues={this.state.data}
                        onSubmit={this.onSubmit}
                        validationSchema={validationSchema}
                    >
                        {(props) => <Form
                            {...props}
                            onError={(error, modalTitle) => this.setState({ error, modalTitle })}
                            back={this.id ? `/myPage/${this.id}?key=${this.key}` : '/'}/>
                        }
                    </Formik>
                </div>
            </div>,
            <Modal error={error} isOpen={showModal} title={modalTitle} close={this.onModalClose} />,
            <Footer />]
        );
    }
}

EventForm.contextType = LocalizationContext;

export default localize(EventForm);
