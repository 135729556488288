import React from 'react';
import s from '../Landing.module.scss';
import localize from "../../../Localizations/Localize";
import Button from "../../../Components/Button/Button";
import Slider from "../../../Components/Slider/Slider";
import windowSize from "react-window-size";

const tabs = [
    { id: 'app', slides: 19},
    { id: 'web', slides: 18}
];

class HowToUse extends React.Component {
    state = {
        tab: tabs[0]
    };

    render() {
        return null;

        const { getString, windowWidth } = this.props;
        const { tab } = this.state;

        const isMobile = windowWidth < 1201;
        return (
            <div className={s.howToUseBlock} id="howToUse">
                <h2>
                    {getString('landing.howToUse.title')}
                </h2>
                <div className={s.buttons}>
                    {tabs.map(e => <Button
                        title={getString(`landing.howToUse.${e.id}.title${isMobile ? 'Mobile' : ''}`)}
                        type={e.id === tab.id && 'primary'}
                        onClick={() => this.setState({ tab: e })}
                    />)}
                </div>
                <Slider tab={tab} />
            </div>
        );
    }
}

export default windowSize(localize(HowToUse));
