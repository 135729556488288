import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { BrowserRouter } from 'react-router-dom'
import './index.scss';
import App from './Containers/App/App';
import datesRu from 'date-fns/locale/ru';
import { registerLocale } from 'react-datepicker';
import {Authorization} from "./Authorization/AuthorizationContext";

registerLocale('ru', datesRu);

smoothscroll.polyfill();

ReactDOM.render(
    <BrowserRouter>
        <Authorization>
            <App />
        </Authorization>
    </BrowserRouter>
, document.getElementById('root'));
