import {getDataUrl} from "../../Helpers/FormatHelper";

const formDataToDto = (data) => ({
        ...data,
        eventRequestId: data.id,
        hashTag: data.hashTag.replace('#', ''),
        startUnixMilliseconds: new Date(data.startUnixMilliseconds).getTime(),
        endUnixMilliseconds: new Date(data.endUnixMilliseconds).getTime(),
        eventType: data.eventType.value,
        currency: data.currency.value,
        privacy: data.privacy.value,
        picture: data.picture && data.picture
            .replace('data:image/png;base64,', '')
            .replace('data:image/jpeg;base64,', '')
            .replace('data:image/;base64,', '')
            .replace('data:image/svg+xml;base64,', ''),
        timeZone: new Date().getTimezoneOffset() / -60
});

const dtoToFormData = (data) => ({
    ...data,
    hashTag: `#${data.hashTag}`,
    startUnixMilliseconds: new Date(data.startUnixMilliseconds),
    endUnixMilliseconds: new Date(data.endUnixMilliseconds),
    eventType: { value: data.eventType },
    currency: getOption(data.currency, currencies),
    privacy: getOption(data.privacy, privacies),
    picture: getDataUrl(data.picture, data.pictureFormat),
    site: data.site || '',
    description: data.description || '',
    started: data.startUnixMilliseconds < new Date()
});

const getOption = (value, options) => {
    for(var i = 0; i < options.length; i++) {
        if (options[i].value === value) {
            return options[i];
        }
    }

    return {};
};

var privacies = [{
    value: false,
    label: 'Открытое'
}, {
    value: true,
    label: 'Закрытое'
}];

var currencies = [{
    value: '$',
    label: '$'
},{
    value: '€',
    label: '€'
}, {
    value: '₽',
    label: 'RUB'
}];

export { formDataToDto, dtoToFormData, getOption, privacies, currencies };