import React from 'react';
import Select from 'react-select';
import cx from 'classnames';
import s from './SelectBox.module.scss';
import {Field} from "formik";
import FastField from "../FastField/FastField";
import { getOption } from '../../Containers/EventForm/DtoHelper';

class SelectBox extends React.Component {
    componentDidMount() {
        const { options, field, form } = this.props;
        setTimeout(() => {
            if (options && options.length > 0) {
                form.setFieldValue(field.name, field.value ? getOption(field.value.value, options) : options[0])
            }
        });
    }

    componentDidUpdate(nextProps) {
        const { options, field, form } = this.props;
        if (options && options[0] && options[0].label !== nextProps.options[0].label) {
            form.setFieldValue(field.name, field.value ? getOption(field.value.value, options) : options[0])
        }
    }

    render(){
        const { title, options, field, form, isDisabled, ...props } = this.props;
        return (
            <div className={s.container}>
                <label>
                    {title || <span style={{visibility: 'hidden'}}>empty</span>}
                </label>
                <Select
                    {...props}
                    {...field}
                    isDisabled={isDisabled}
                    placeholder=""
                    onChange={e => form.setFieldValue(field.name, e)}
                    className={cx(s.select, isDisabled && s.disabled)}
                    options={options}
                    styles={{ indicatorSeparator: () => ({ display: 'none' })}}
                    isSearchable={false}
                />
            </div>
        );
    }
}

const Wrapper = (props) => props.slow
    ? <Field {...props} component={SelectBox} />
    : <FastField {...props} component={SelectBox} />;

export default Wrapper;
