import React from 'react';
import MaskedInput from '../../../../Components/Input/Input'
import s from './Input.module.scss';

const Input = ({ ...props }) => (
    <MaskedInput
        {...props}
        className={s.input}
        mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
        placeholderChar=" "
        placeholder="00:00:00"
    />
);

export default Input;
