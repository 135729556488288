import React from 'react';
import s from './Event.module.scss';
import EventCard from "./EventCard/EventCard";
import Events from "../Events/Events";
import Footer from "../../Components/Footer/Footer";

class Event extends React.Component {
    render() {
        return (
            <div className={s.container}>
                <EventCard id={this.props.match.params.id} />
                <Events />
                <Footer />
            </div>
        );
    }
}

export default Event;
