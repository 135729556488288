import React from 'react';
import cx from 'classnames';
import s from './TextArea.module.scss';
import {Field} from "formik";
import FastField from "../FastField/FastField";

const TextArea = ({ title, field, form, ...props }) => {
    var invalid = false;
    var validation = form.submitCount || form.touched[field.name];
    if (validation) {
        invalid = form.errors[field.name];
    }
    return (
        <div className={s.container}>
            <label>{title}</label>
            <textarea
                {...field}
                {...props}
                className={cx(invalid && s.invalid)}
            />
        </div>
    );
};

const Wrapper = (props) => props.slow
    ? <Field {...props} component={TextArea} />
    : <FastField {...props} component={TextArea} />
;

export default Wrapper;
