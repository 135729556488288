import React from 'react';
import logo from '../../Resources/Images/logo.svg';
import s from './Footer.module.scss';
import Button from '../Button/Button';
import localize from "../../Localizations/Localize";
import { moveTo } from '../../Helpers/DomHelper';
import {Link, withRouter} from "react-router-dom";
import appStore from './appStore.svg';
import gplay from './gplay.svg';
import youtube from './youtube.svg';
import SubscribeEmail from "../SubscribeEmail/SubscribeEmail";
import {headerItems} from "../../constants";
import {LocalizationContext} from "../../Localizations/LocalizationContext";

class Footer extends React.Component {
    onItemClick = (id) => {
        if (this.props.location.pathname !== '/') {
            this.props.history.push('');
        }

        setTimeout(() => moveTo(id));
    };

    render() {
        const { language } = this.context;
        const { getString } = this.props;
        return (
            <div className={s.footer}>
                <SubscribeEmail />
                <div className={s.content}>
                    <div className={s.left}>
                        <img src={logo}/>
                        <p>Prover {new Date().getFullYear()}</p>
                    </div>
                    <div className={s.right}>
                        <ul>
                            {headerItems.map(e => <li onClick={() => this.onItemClick(e)}>{getString(`global.${e}`)}</li>)}
                        </ul>
                        {/* <Link to="/create">
                            <Button title={getString('global.createProject')} size="small" />
                        </Link> */}
                    </div>
                    <div className={s.app}>
                        <span>
                            {getString('footer.downloadCall')}
                        </span>
                        <a target="_blank" href={getString('global.appleAppUrl')}>
                            <img src={appStore} />
                        </a>
                        <a style={{ marginTop: '14px' }} target="_blank" href="https://play.google.com/store/apps/details?id=com.crowdfilmingforyou">
                            <img style={{ width: '201px' }} src={gplay} />
                        </a>
                        <a style={{ marginTop: '14px' }} target="_blank" href="https://www.youtube.com/channel/UCXNkaczMvvrx5gNS_bXEqtA">
                            <img style={{ width: '201px' }} src={youtube} />
                        </a>
                    </div>
                </div>
                <a target="_blank" href={`/privacy_${language}.pdf`}>{getString('footer.privacy')}</a>
            </div>
        );
    }
}

Footer.contextType = LocalizationContext;

export default withRouter(localize(Footer));
