import React from 'react';
import * as languages from './langs';
import _ from 'lodash';
import { LocalizationContext } from './LocalizationContext';

function localize(Component) {
    class LocalizedComponent extends React.Component {
        getString = (value) => {
          return _.get(languages[this.context.language], value) || value;
        };

        render() {
            return <Component {...this.props} getString={this.getString} />;
        }
    }

    LocalizedComponent.contextType = LocalizationContext;

    return LocalizedComponent;
}

export default localize;
