import React from 'react';
import s from './File.module.scss';
import Button from "../../../../Components/Button/Button";
import { Field } from "formik";
import { images } from '../../../../Components/EventPicture/Helper';

class File extends React.Component {
    handleChange = () => {
        var file = this.input.files[0];
        if (file) {
            if (file.type !== "image/jpeg" && file.type !== "image/png") {
                this.props.onError('fileTypeError');
                return;
            }

            if (file.size > 500 * 1024) {
                this.props.onError('fileSizeError');
                return;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => this.setValue(reader.result, file.type.replace('image/', ''));
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        } else {
            this.setValue();
        }
    };

    setValue = (value, format) => {
        this.props.form.setFieldValue(this.props.field.name, value);
        this.props.form.setFieldValue('pictureFormat', format);
    };


    render() {
        const { field, title, form, disabled } = this.props;
        const { value } = field;
        const type = form.values.eventType && form.values.eventType.value;
        return <div className={s.container}>
            <img src={value || images[type] || images['Disco']} />
            {disabled || <Button title={title} onClick={() => this.input.click()} />}
            <input
                accept="image/jpeg, image/png"
                type="file"
                ref={e => this.input = e}
                onChange={this.handleChange}
            />
        </div>;
    }
}

const Wrapper = (props) => <Field {...props} component={File} />;

export default Wrapper;
