import * as yup from "yup";
import * as constants from "../../constants";

const validationSchema = yup.object().shape({
    name: yup.string().min(2).max(50).required(),
    site: yup.string().lowercase().matches(constants.siteRegex),
    location: yup.string().required().when(['latitude', 'longitude'], {
        is: (latitude, longitude) => latitude && longitude,
        otherwise: yup.string().max(-1)
    }),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
    startUnixMilliseconds: yup.date()
        .when('started', (started, s) => started ? s : s.min(new Date())).required(),
    endUnixMilliseconds: yup.date()
        .when('startUnixMilliseconds', (start, s) => start ? s.min(new Date(start.getTime() + 60 * 1000)) : s)
        .required(),
    hashTag: yup.string().matches(constants.hashTagRegex).required(),
    price: yup.number().min(0),
    description: yup.string().max(2000),
    email: yup.string().email().required(),
    reCaptchaToken: yup.string().required()
});

export default validationSchema;