import React from 'react';
import cx from 'classnames';
import s from './MobileHeader.module.scss';

const MobileHeader = ({ title, children, className }) => (
    <h2 className={cx(s.container, className)}>
        {title || children}
    </h2>
);

export default MobileHeader;
