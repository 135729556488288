import React from 'react';
import localize from "../../../Localizations/Localize";
import BaseModal from '../../../Components/BaseModal/BaseModal';

const Modal = ({ isOpen, error, close, getString, title }) =>
    <BaseModal
        opened={isOpen || error}
        close={close}
        title={title || (error ? getString('form.modal.error') : getString('form.modal.title'))}
        content={error || getString('form.modal.body')}
        closeButtonTitle={getString('form.modal.close')}
        centered
    />;

export default localize(Modal);
