import React from 'react';
import cx from 'classnames';
import s from './SubscribeEmail.module.scss';
import api from "../../Containers/api";
import localize from "../../Localizations/Localize";
import {Formik, Form, Field } from "formik";
import Button from "../Button/Button";
import * as yup from "yup";
import Modal from "../../Containers/Progress/Modal/Modal";
import config from '../../config';
import {LocalizationContext} from "../../Localizations/LocalizationContext";

class SubscribeEmail extends React.Component {
    state = {
        error: false,
        success: false,
        errorMessage: null
    };

    onSubmit = data => {
        window.ym(config.yandexMetrikaId, 'reachGoal', 'subscribe');
        api.post('subscribe', data)
            .then(() => this.setState({success: true}))
            .catch(response => response.json()
                .then(e => this.setState({ errorMessage: e.userMessage }))
                .catch().then(() => this.setState({ error: true }))
            );
    };

    render() {
        const { language } = this.context;
        const { getString } = this.props;
        const { error, success, errorMessage } = this.state;

        return (
            <div className={s.container}>
                <div className={s.title}>
                    {getString('landing.subscribeEmail.title')}
                </div>
                {success || <Formik onSubmit={this.onSubmit} validationSchema={yup.object().shape({
                    email: yup.string().email().required()
                })}
                        render={({ errors, touched }) => <Form className={s.form}>
                            <Field className={cx(s.input, touched.email && errors.email && s.invalid)} name="email" placeholder="Email" />
                            <Button htmlType="submit" title={getString('landing.subscribeEmail.subscribe')} />
                        </Form>}
                />}
                {success || <div className={s.privacy}>
                    {getString('landing.subscribeEmail.agreeWith')}
                    <a style={{ marginLeft: '5px' }} target="_blank" href={`/privacy_${language}.pdf`}>{getString('landing.subscribeEmail.policy')}</a>
                </div>}
                {success && <div className={s.success}>
                    {getString('landing.subscribeEmail.success')}
                </div>}
                <Modal
                    isOpen={error}
                    error={errorMessage || getString('landing.subscribeEmail.error')}
                    close={() => this.setState({ error: false, errorMessage: null })}
                />
            </div>
        );
    }
}

SubscribeEmail.contextType = LocalizationContext;

export default localize(SubscribeEmail);
