import * as yup from "yup";
import moment from "moment";

const validateDuration = (str, maxDuration) => str.test(
    'valid_time',
    'Time is invalid.',
    e => {
        var duration = getMilliseconds(e);
        return duration >= 0 && duration <= maxDuration;
    }
);

const getMilliseconds = str => moment.duration(str).asMilliseconds();

const getValidationSchema = (duration) => yup.object().shape({
    intervals: yup.array().of(
        yup.object().shape({
            from: validateDuration(yup.string(), duration)
                .required(),
            to: validateDuration(yup.string(), duration)
                .when('from', (from, s) => from ? s.test(
                    'valid_time',
                    'Time is invalid.',
                    e => getMilliseconds(e) > getMilliseconds(from)) : s
                ).required()
        })
    )
});

export default getValidationSchema;