import React from 'react';
import cx from 'classnames';
import { Field, getIn } from 'formik';
import MaskedInput from 'react-text-mask'
import s from './Input.module.scss';
import FastField from "../FastField/FastField";

const Input = ({ title, required, placeholder, onChange, className, field, form, ...props }) => {
    var invalid = false;
    var validation = form.submitCount || getIn(form.touched, field.name);
    if (validation) {
        invalid = getIn(form.errors, field.name);
    }

    return (
        <div className={s.container}>
            <label required={required}>{title}</label>
            <MaskedInput
                mask={false}
                {...field}
                {...props}
                className={cx(className, {
                    [s.invalid]: invalid
                })}
                placeholder={placeholder}
            />
            {invalid && invalid.message && <p className={s.error}>
                {invalid.message}
            </p>}
        </div>
)};

const Wrapper = (props) => props.slow
    ? <Field {...props} component={Input} />
    : <FastField {...props} component={Input} />
;

export default Wrapper;

export { Input };
