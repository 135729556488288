import config from "../config";
import {getCurrentLanguage} from "../Localizations/LocalizationContext";
import {storageAccessToken, storageEmail, storageExpires, storageRole} from "../constants";

function post(method, data, check = true) {
    return checkLogin(check).then(() =>
        fetch(getUrl(method), {
            method: 'POST',
            headers: getHeaders(),
            body: prepareData(data)
        }).then(e => getData(e, () => post(method, data))));
}

function get(method, params) {
    return checkLogin().then(() =>
        fetch(getUrl(method, params), {
            method: 'GET',
            headers: getHeaders(),
        }).then(e => getData(e, () => get(method, params))));
}

function upload(method, data) {
    var formData = new FormData();

    for (var name in data) {
        formData.append(name, data[name]);
    }

    formData.append('locale', getCurrentLanguage());

    var headers = getHeaders();
    delete headers['Content-Type'];

    return checkLogin().then(() =>
        fetch(getUrl(method), {
            method: 'POST',
            headers,
            body: formData
        }).then(e => getData(e, () => upload(method))));
}

function download(method, data) {
    return checkLogin().then(() =>
        fetch(getUrl(method), {
            headers: getHeaders(),
            method: 'POST',
            body: prepareData(data)
        }).then(response => {
            if (!response.ok) {
                throw response;
            }

            return response.blob();
        }));
}

const prepareData = data => JSON.stringify({
    ...data,
    locale: getCurrentLanguage()
});

function getData(response, retry) {
    if (response.ok) {
        return response.json().catch(() => response);
    } else {
        if (response.status === 401) {
            return login().then(retry)
        }

        throw response;
    }
}

function getUrl(method, params) {
    return `${config.apiUrl}/${method}${params ? `?${new URLSearchParams(params)}&locale=${getCurrentLanguage()}` : ''}`
}

function getHeaders() {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(storageAccessToken)}`,
        'Accept-Language': getCurrentLanguage()
    };
}

function checkLogin(check) {
    if (check && Date.parse(localStorage.getItem(storageExpires)) <= new Date()) {
        return login();
    }

    return Promise.resolve();
}

function login() {
    return post('auth/login', {
        login: config.apiLogin,
        key: config.apiKey
    }, false).then(thenLoggedIn);
}

function userLogin({ email, password, reCaptchaToken }) {
    return post('user/login', {
        email,
        password,
        reCaptchaToken
    }, false).then(data => thenLoggedIn(data, email));
}

function logout(){
    thenLoggedIn({});
}

function thenLoggedIn(data, email) {
    localStorage.setItem(storageExpires, data.expires || '');
    localStorage.setItem(storageAccessToken, data.access_token || '');
    localStorage.setItem(storageRole, data.role || '');
    localStorage.setItem(storageEmail, email || '');
    window.setRole(data.role);
}

export default { checkLogin, post, get, upload, download, userLogin, logout };