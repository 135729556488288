import React from 'react';
import s from './Modal.module.scss';
import localize from "../../../Localizations/Localize";
import disk from './disk.svg';
import BaseModal from "../../../Components/BaseModal/BaseModal";

const Modal = ({ isOpen, close, download, getString, error }) =>
    <BaseModal
        opened={isOpen}
        close={close}
        title={error ? getString('progress.Error') : getString('progress.modal.title')}
        closeButtonTitle={error ? getString('progress.modal.ok') : getString('progress.modal.cancel')}
        centered
    >
        {error || <div className={s.item} onClick={download}>
            <img src={disk} />
            <span>{getString('progress.modal.disk')}</span>
        </div>}
    </BaseModal>;

export default localize(Modal);
