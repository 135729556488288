import React from 'react';
import s from './Modal.module.scss';
import BaseModal from "../../../../../Components/BaseModal/BaseModal";

const Modal = ({ ...props }) => (
    <BaseModal
        {...props}
        containerClassName={s.container}
        className={s.modal}
        buttons={false}
    />
);

export default Modal;
