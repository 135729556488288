import React from 'react';
import cx from 'classnames';
import s from './Slider.module.scss';
import slides from "./slides";
import Slide from "./Slide/Slide";
import { Fade } from "react-reveal";
import makeCarousel from 'react-reveal/makeCarousel';
import windowSize from "react-window-size";

const Carousel = makeCarousel(({ children }) => children);

class Slider extends React.Component {
    state = {
        slide: 1,
        ready: true
    };

    prevSlide = () => {
        const { slide } = this.state;
        if (slide !== 1) {
            this.setState({ slide: slide - 1 });
        }
    };

    nextSlide = () => {
        const { tab } = this.props;
        const { slide } = this.state;
        if (slide !== tab.slides) {
            this.setState({ slide: slide + 1 });
        }
    };

    onTouchStart = e => {
        var touchobj = e.changedTouches[0];
        this.startX = touchobj.pageX;
        this.startY = touchobj.pageY;
        this.startTime = new Date().getTime();
    };

    onTouchEnd = e => {
        var touchobj = e.changedTouches[0];
        const distX = touchobj.pageX - this.startX;
        const distY = touchobj.pageY - this.startY;
        const elapsedTime = new Date().getTime() - this.startTime;
        if (elapsedTime <= 1000) {
            if (Math.abs(distX) >= 50 && Math.abs(distY) <= Math.abs(distX)){
                if (distX < 0) {
                    this.nextSlide();
                } else {
                    this.prevSlide();
                }
            }
        }
    };

    componentDidMount() {
        this.slider.handleReveal = () => {
            this.setState({ ready: true });
        };

        this.container.addEventListener('touchstart', this.onTouchStart);
        this.container.addEventListener('touchend', this.onTouchEnd);
    }

    componentWillUnmount() {
        this.container.removeEventListener('touchstart', this.onTouchStart);
        this.container.removeEventListener('touchend', this.onTouchEnd);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.slide !== this.state.slide) {
            this.setState({ ready: false });
            this.slider.setState({
                current: this.state.slide - 1,
                next: prevState.slide - 1,
                appear: true,
                swap: this.state.ready ? !this.slider.state.swap : this.slider.state.swap,
                backwards: prevState.slide > this.state.slide
            });
        }

        if (prevProps.tab.id !== this.props.tab.id) {
            this.setState({ slide: 1 });
        }
    }

    getImage = i => {
        const { tab, windowWidth } = this.props;
        const isMobile = windowWidth < 1201;

        return (isMobile && slides[tab.id].mobile) ? slides[tab.id].mobile[i + 1] : slides[tab.id][i + 1];
    };

    render() {
        const { tab } = this.props;
        const { slide } = this.state;

        return (
            <div className={s.container} ref={e => this.container = e}>
                <Carousel defaultWait={0} maxTurns={0} ref={e => this.slider = e} swipe={false}>
                    {[...new Array(tab.slides)].map((e, i) => <Fade right>
                        <Slide tab={tab} slide={i + 1} image={this.getImage(i)} />
                    </Fade>)}
                </Carousel>
                <div className={cx(s.arrowLeft, slide !== 1 && s.active)} onClick={this.prevSlide} />
                <div className={cx(s.arrowRight, slide !== tab.slides && s.active)} onClick={this.nextSlide} />
                <div className={s.hint} />
            </div>
        );
    }
}

export default windowSize(Slider);
