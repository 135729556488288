import React from 'react';
import cx from 'classnames';
import s from './Slide.module.scss';
import localize from "../../../Localizations/Localize";

const Slide = ({ slide, tab, image, getString }) => (
    <div className={cx(s.container, image && s.hasRight, s[tab.id])}>
        <div className={s.left}>
            <div
                className={s.text}
                dangerouslySetInnerHTML={{ __html: getString(`landing.howToUse.${tab.id}.${slide}`)}}
            />
            <div className={s.count}>
                {slide}/{tab.slides}
            </div>
        </div>
        {image && <div className={s.right}>
            <img src={image} />
        </div>}
    </div>
);

export default localize(Slide);
