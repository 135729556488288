import moment from "moment";
import _ from 'lodash';

const getMomentFromDuration = value => moment.utc(moment.duration(value).asMilliseconds());

const formatDuration = (value, short) => {
    var date = getMomentFromDuration(value);
    if (short) {
        if (!date.hours()) {
            return date.format('m:ss');
        }
    }
    return date.format('HH:mm:ss');
};

const formatDurationFromSeconds = (value, short) => formatDuration(moment.duration(value, 's'), short);

const formatPF = price => price && (price / Math.pow(10, 6)).toFixed(6).replace(/\d(?=(\d{3})+\.)/g, '$& ') + ' PF';

const formatDays = (n, language) => {
    if (language !== 'ru') {
        if (language === 'vi') {
            return `${n} ngày`;
        }

        return `${n} day${n > 1 ? 's' : ''}`
    }

    if (n % 100 > 10 && n % 100 < 20) {
        return `${n} дней`;
    }

    switch(_.last(n.toString())) {
        case '1':
            return `${n} день`;
        case '2':
        case '3':
        case '4':
            return `${n} дня`;
        default:
            return `${n} дней`;
    }
};

const timeLeft = (startUnixMilliseconds, language) => {
    var leftMilliseconds = startUnixMilliseconds - new Date();
    if (leftMilliseconds <= 0) {
        switch (language) {
            case 'ru':
                return 'Идет...';
            case 'vi':
                return 'Hiện nay...';
            default:
                return 'Now...';

        }
    }

    var days = Math.floor(leftMilliseconds / (1000 * 60 * 60 * 24));

    if (days) {
        return formatDays(days, language);
    }

    return formatDuration(leftMilliseconds)
};

const formatDate = milliseconds => moment(milliseconds).format('DD.MM.YYYY HH:mm');

const maxLength = (str, maxLength) => {
    if (!str || str.length <= maxLength) {
        return str;
    }

    return `${str.slice(0, maxLength - 3).trim()}...`;
};

const getDataUrl = (data, format) => `data:image/${format === 'svg' ? 'svg+xml' : 'png'};base64,${data}`;

export { formatPF, formatDuration, timeLeft, formatDurationFromSeconds, formatDate,
    maxLength, getDataUrl
};
