import React from 'react';
import Input from "../../../../../Components/Input/Input";
import * as yup from "yup";
import api from "../../../../api";
import {withRouter} from "react-router-dom";
import BaseForm from "../BaseForm/BaseForm";
import localize from "../../../../../Localizations/Localize";

class Register extends React.Component {
    onSubmit = data => api.post('user/register', data)
        .then(() => {
            this.props.history.push('/emailSent/validation');
            this.props.setModal(false);
        });

    render() {
        const { setModal, getString } = this.props;

        return (
            <BaseForm
                close={() => setModal(false)}
                onSubmit={this.onSubmit}
                validationSchema={yup.object().shape({
                    email: yup.string().email().required(),
                    password: yup.string().min(8, { message: getString('authorization.passwordError') }).required(),
                    reCaptchaToken: yup.string().required()
                })}
            >
                <Input required name="email" title="E-mail"/>
                <Input
                    type="password"
                    required
                    name="password"
                    title={getString('authorization.password')}
                />
                <Input name="referralCode" title={getString('authorization.referralCode')}/>
            </BaseForm>
        );
    }
}

export default localize(withRouter(Register));
