const moveTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({behavior: 'smooth'});
    }

    if (id === 'events') {
        window.reloadEvents();
    }
};

export { moveTo };
