import React, { Component } from 'react';
import _ from 'lodash';
import s from './Events.module.scss';
import EventItem from './Item/Item';
import Button from '../../Components/Button/Button';
import localize from "../../Localizations/Localize";
import api from "../api";
import {timeLeft} from "../../Helpers/FormatHelper";
import {LocalizationContext} from "../../Localizations/LocalizationContext";
import Filter from "./Filter/Filter";
import Loader from "../../Components/Loader/Loader";

const countOnPage = 8;

class Events extends Component {
    state = {
        list: [],
        eventType: null,
        hasNext: false
    };

    componentDidMount() {
        api.get('getEventTypes').then(data => this.setState(data));
        this.loadEvents();
        window.reloadEvents = () => {
            this.state.list = [];
            this.loadEvents();
        }
    }

    loadEvents = () => {
        if (this.state.loading) return;
        this.setState({ loading: true });

        api.get('getEvents', {
            offset: this.state.list.length,
            count: countOnPage + 1,
            eventType: this.state.eventType || '',
            orderBy: 'StartUnixMilliseconds'
        }).then(list => this.setState({
            list: [...this.state.list, ..._.take(list, countOnPage)],
            hasNext: list.length > countOnPage
        })).catch(response => {
            if (response.status === 404) {
                this.setState({hasNext: false});
            }
        }).then(() => this.setState({ loading: false }));
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.eventType !== this.state.eventType) {
            this.setState({ list: []}, this.loadEvents);
        }
    }

    render() {
        const { getString } = this.props;
        const { eventTypes, eventType, list, hasNext, loading } = this.state;
        const { language } = this.context;

        return null;

        return (
            <div className={s.container} id="events">
                <div className={s.inner}>
                    <h2>{getString('global.events')}</h2>
                    <Filter
                        eventType={eventType}
                        eventTypes={eventTypes}
                        onChange={eventType => this.setState({ eventType })}
                    />
                    <div className={s.items}>
                        {list.map(e => <EventItem
                            id={e.id}
                            title={e.name}
                            description={e.description}
                            src={e.picture}
                            type={e.eventType}
                            count={e.participantCount}
                            time={timeLeft(e.startUnixMilliseconds, language)}
                            icon={e.icon}
                            pictureFormat={e.pictureFormat}
                            iconFormat={e.iconFormat}
                        />)}
                    </div>
                    {loading && <Loader className={s.loader} />}
                    {hasNext && <Button
                        width="200px"
                        title={getString('event.others')}
                        className={s.button}
                        onClick={this.loadEvents}
                    />}
                </div>
            </div>
        );
    }
}

Events.contextType = LocalizationContext;

export default localize(Events);
