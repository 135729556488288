import React from 'react';
import { Form as FormikForm } from 'formik';
import s from './Form.module.scss';
import Input from "../../../Components/Input/Input";
import SelectBox from "../../../Components/SelectBox/SelectBox";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import TextArea from "../../../Components/TextArea/TextArea";
import Button from "../../../Components/Button/Button";
import config from '../../../config';
import Captcha from "../../../Components/Captcha/Captcha";
import File from "./File/File";
import {Link} from "react-router-dom";
import * as constants from '../../../constants';
import {currencies, privacies} from "../DtoHelper";
import localize from '../../../Localizations/Localize';
import {LocalizationContext} from "../../../Localizations/LocalizationContext";
import api from "../../api";
import {formatDate} from "../../../Helpers/FormatHelper";

class Container extends React.Component {
    state = {
        language: this.context.language
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.propsOrStateChanged(nextProps, nextState)
            || nextContext.language !== this.context.language;
    }

    propsOrStateChanged = (nextProps, nextState) =>
        nextProps.errors.length !== this.props.errors.length
        || (!this.state.eventTypes || !nextState.eventTypes || nextState.eventTypes.length !== this.state.eventTypes.length)
        || nextProps.isSubmitting !== this.props.isSubmitting;

    initAutocomplete = () => {
        this.autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            {types: ['geocode']}
        );
        this.autocomplete.addListener('place_changed', this.fillInAddress);
    };

    fillInAddress = () => {
        var place = this.autocomplete.getPlace();

        this.props.setValues({
            ...this.props.values,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            location: place.formatted_address,
        });
    };

    setAPILanguage = (lang, libraries = ['places']) => {
        //Destroy old API
        document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
            script.remove();
        });
        if (window.google) delete window.google.maps;

        //Generate new Google Maps API script
        let newAPI = document.createElement('script');
        newAPI.src = 'https://maps.googleapis.com/maps/api/js?libraries=' + libraries.join(',')
            + '&key=' + config.googleApiKey
            + '&language=' + lang + '&callback=googleMapsAPILoaded';

        //Callback for the Google Maps API src
        window.googleMapsAPILoaded = () => {
            let event = new CustomEvent('googleMapsAPILoaded');
            window.dispatchEvent(event);
        };

        //Wait for the callback to be executedЗа
        let apiLoaded = new Promise(resolve => {
            window.addEventListener('googleMapsAPILoaded', () => {
                resolve();
            });
        });

        //Start the script
        document.querySelector('head').appendChild(newAPI);

        return apiLoaded;
    };

    componentDidMount() {
        api.get('getEventTypes').then(data => this.setState(data));
        this.initGooglePlaces();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.propsOrStateChanged(prevProps, prevState) && this.context.language !== this.state.language) {
            this.state.language = this.context.language;
            this.initGooglePlaces();
        }

        try {
            this.scrollToInvalidField();
        }catch (e) {console.log(e)}

    }

    scrollToInvalidField = () => {
        const { isSubmitting, errors } = this.props;

        if (isSubmitting) {
            var firstError = Object.keys(errors)[0];
            if (isSubmitting && firstError) {
                var element = document.getElementsByName(firstError)[0];
                if (firstError === 'reCaptchaToken') {
                    element = document.getElementsByName('g-recaptcha-response')[0].parentElement;
                }
                var { top } = element.getBoundingClientRect();
                var topOfPage = 130;
                if (top < topOfPage) {
                    window.scrollTo({
                        top: window.scrollY + top - topOfPage,
                        behavior: 'smooth'
                    });
                }
            }
        }
    };

    initGooglePlaces = () => this.setAPILanguage(this.context.language).then(this.initAutocomplete);

    render() {
        return <Form {...this.props} {...this.state} language={this.context.language} />
    }
}

const Form = ({ values, getString, language, eventTypes, back, onError, isSubmitting }) => {
    const localizedEventTypes = eventTypes && eventTypes.map(e => ({
        label: getString(`form.eventTypes.${e}`),
        value: e
    }));
    const localizedPrivacies = privacies.map(e => ({
        label: getString(`form.privacyTypes.${e.value ? 'private' : 'forAll'}`),
        value: e.value
    }));

    var startPlaceholder = new Date();
    startPlaceholder.setMinutes(0);
    startPlaceholder.setHours(startPlaceholder.getHours() + 1);
    var endPlaceholder = new Date(startPlaceholder);
    endPlaceholder.setHours(endPlaceholder.getHours() + 3);

    const { started } = values;

    return (
    <div className={s.form}>
        <div className={s.fields}>
            <FormikForm>
                <div className={s.row}>
                    <Input name="name" disabled={started} required title={getString('form.name')} placeholder={getString('form.myEvent')} />
                    <Input name="site" disabled={started} title={getString('form.site')} placeholder="www.myevent.ru" />
                </div>
                <div className={s.row}>
                    <SelectBox
                        isDisabled={started}
                        name="eventType"
                        title={getString('form.eventType')}
                        options={localizedEventTypes}
                        key={JSON.stringify(localizedEventTypes)}
                    />
                    <SelectBox
                        isDisabled={started}
                        name="privacy"
                        title={getString('form.privacy')}
                        options={localizedPrivacies}
                    />
                </div>
                <div className={s.row}>
                    <Input name="location"
                           disabled={started}
                           required
                           id="autocomplete"
                           className={s.location}
                           title={getString('form.address')}
                           placeholder="Russia, Moscow, Gorgky Park"
                           slow
                    />
                </div>
                <div className={s.row}>
                    <DatePicker
                        disabled={started}
                        name="startUnixMilliseconds"
                        title={getString('form.begins')}
                        placeholder={formatDate(startPlaceholder)}
                        language={language}
                        required
                    />
                    <DatePicker
                        name="endUnixMilliseconds"
                        title={getString('form.ends')}
                        placeholder={formatDate(endPlaceholder)}
                        language={language}
                        required
                    />
                </div>
                <div className={s.row}>
                    <Input
                        disabled={started}
                        required
                        name="hashTag"
                        title={getString('form.hashTag')}
                        placeholder="#my_event"
                        showMask
                        mask={e => {
                        e = e.indexOf('#') >= 0 ? e : '#' + e;
                        var result = ['#'];
                        var match = /#[a-zA-Z\dа-яА-Я_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+/.exec(e);
                        var n = ((match && match[0]) || '#').length - 1;
                        for (var i = 0; i < n; i++) {
                            result.push(constants.hashTagCharRegex);
                        }
                        return result.length === 1 ? [''] : result
                    }}/>
                    <Input name="price" title={getString('form.price')} placeholder="100"
                           mask={e => {
                               var result = [];
                               var n = e.replace(/\D/g, '').length;
                               for (var i = 0; i < n; i++) {
                                   result.push(/\d/);
                               }
                               return result;
                           }}
                    />
                    <SelectBox name="currency" options={currencies} />
                </div>
                <div className={s.row}>
                    <TextArea
                        name="description"
                        title={getString('form.description') + ':'}
                        placeholder={getString('form.descriptionPlaceholder')}
                    />
                </div>
                <div className={s.row}>
                    <Input disabled={started} required name="email" title="E-mail" />
                </div>
                <div className={s.row}>
                    <Captcha language={language} key={language} name="reCaptchaToken" />
                </div>
                <div className={s.row}>
                    <Link to={back}>
                        <Button width="143px" title={getString('form.back')} />
                    </Link>
                    <Button disabled={isSubmitting} width="143px" title={getString('form.send')} htmlType="submit" />
                </div>
                <div className={s.row}>
                    <small style={{ whiteSpace: 'nowrap' }}>
                        {getString('form.policy')}
                    </small>
                </div>
            </FormikForm>
        </div>
        <div className={s.cover}>
            <div className={s.title}>
                {getString('form.cover')}
            </div>
            <File
                name="picture"
                disabled={started}
                title={getString('form.uploadCover')}
                onError={(error) => onError(getString(`form.${error}`), getString('form.fileErrorTitle'))}
            />
            {started || <small>
                {getString('form.coverRecommendation')}
            </small>}
        </div>
    </div>
)};

Container.contextType = LocalizationContext;

export default localize(Container);
