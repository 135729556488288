import React from 'react';
import cx from 'classnames';
import s from './Button.module.scss';

const Button = ({ title, type, size, className, onClick, width, maxWidth, htmlType, disabled }) => {
    return (
        <button
            className={cx(s.button, s[type], s[size], className, disabled && s.disabled)}
            onClick={onClick}
            style={{ minWidth: width, maxWidth }}
            type={htmlType}
            disabled={disabled}
        >
            {title}
        </button>
    );
};

Button.defaultProps = {
    width: 'auto',
    maxWidth: 'auto',
    type: 'primary',
    htmlType: 'button'
};

export default Button;
