import React from 'react';
import { config, Spring } from 'react-spring';
import s from '../Landing.module.scss';
import item1 from '../Images/hiw6.svg';
import item2 from '../Images/hiw7.svg';
import item3 from '../Images/hiw8.svg';
import item4 from '../Images/hiw9.svg';
import Button from '../../../Components/Button/Button';
import handleViewport from 'react-in-viewport';
import localize from "../../../Localizations/Localize";
import {Link} from "react-router-dom";


const items = [
    {id: 1, img: item1},
    {id: 2, img: item2},
    {id: 3, img: item3},
    {id: 4, img: item4}
];

const OrganizationsBlock = ({inViewport, innerRef, getString}) => {
    return (
        <div className={s.block_3} id="forOrgs" ref={innerRef}>
            <div className={s.content}>
                <h2 dangerouslySetInnerHTML={{__html: getString('landing.block4title')}} />
                <div className={s.features}>
                    {[...new Array(4)].map((c, i) =>

                        <div className={s.feature}>
                            <h3>{getString('landing.block4feature' + (i + 1))}</h3>
                            <p dangerouslySetInnerHTML={{__html: getString('landing.block4feature' + (i + 1) + 'content')}} />
                        </div>
                    )}
                </div>
                <div className={s.items}>
                    {inViewport && items.map((item, index) =>
                        <Spring
                            to={{transform: 'translate3d(0,0,0)', opacity: 1}}
                            from={{transform: `translate3d(-${140 * index + 90}px,0,0)`, opacity: 0}}
                            config={config.slow}
                            delay={1000 * index}
                        >
                            {props => <div className={s.item} style={props}>
                                <div className={s.image} style={{opacity: props.opacity}}>
                                    <img src={item.img} />
                                </div>
                                <span dangerouslySetInnerHTML={{__html: getString(`landing.block4item` + item.id)}} />
                            </div>}
                        </Spring>)}
                </div>
                {/* <Link to="/create">
                    <Button
                        title={getString('global.createProject')}
                        type="primary"
                        className={s.button}
                    />
                </Link> */}
            </div>
        </div>
    );
};

export default handleViewport(localize(OrganizationsBlock), {rootMargin: '-1.0px'});
