import React from 'react';
import BaseDatePicker from 'react-datepicker';
import cx from 'classnames';
import s from './DatePicker.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import {Field} from "formik";
import FastField from "../FastField/FastField";
import localize from "../../Localizations/Localize";
import moment from "moment";

const DatePicker = ({ title, required, width, field, form, placeholder, language, getString, disabled }) => {
    var invalid = false;
    var validation = form.submitCount || form.touched[field.name];
    if (validation) {
        invalid = form.errors[field.name];
    }

    return (
        <div className={s.container} style={{width}}>
            <label required={required}>
                {title}
            </label>
            <BaseDatePicker
                name={field.name}
                disabled={disabled}
                className={cx(invalid && s.invalid, disabled && s.disabled)}
                dateFormat="dd.MM.yy HH:mm"
                timeFormat="HH:mm"
                onBlur={() => form.setFieldTouched(field.name)}
                selected={field.value}
                placeholderText={placeholder}
                disabledKeyboardNavigation
                onChangeRaw={e => {
                    const { value } = e.target;
                    const date = moment(value, 'DD.MM.YY HH:mm');
                    if (date.isValid()) {
                        form.setFieldValue(field.name, date.toDate());
                    }
                }}
                onChange={e => form.setFieldValue(field.name, e)}
                locale={language || 'ru'}
                timeCaption={getString('form.time')}
                showTimeSelect
            />
        </div>
    );
};

DatePicker.defaultProps = {
    width: 'unset'
};

const Wrapper = (props) => props.slow
    ? <Field {...props} component={DatePicker} />
    : <FastField {...props} component={DatePicker} />

;

export default localize(Wrapper);
