import React from 'react';

const languages = {
    en: 'en',
    ru: 'ru',
    vi: 'vn'
};

const getCurrentLanguage = () => localStorage.getItem('selectedLanguage') || languages.en;

const LocalizationContext = React.createContext({
    language: languages.en,
    changeLanguage: () => {
    }
});

export default class Localization extends React.Component {
    state = {
        language: getCurrentLanguage()
    };

    changeLanguage = (language) => {
        if (Object.keys(languages).includes(language)) {
            this.setState({ language });
            localStorage.setItem('selectedLanguage', language);
        }
    };

    getContext = () => ({
        language: this.state.language,
        changeLanguage: this.changeLanguage
    });

    render() {
        return (
            <LocalizationContext.Provider value={this.getContext()}>
                {this.props.children}
            </LocalizationContext.Provider>
        );
    }
}

export { LocalizationContext, languages, getCurrentLanguage };
