import React from 'react';
import s from './PageHeader.module.scss';
import windowSize from "react-window-size";

const PageHeader = ({ children, mobileTitle, windowWidth }) => (
    <h1 className={s.container}>
        {windowWidth > 1200 ? children : mobileTitle || children}
    </h1>
);

export default windowSize(PageHeader);
