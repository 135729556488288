import React from 'react';
import Input from "../../../../../Components/Input/Input";
import * as yup from "yup";
import api from "../../../../api";
import {withRouter} from "react-router-dom";
import BaseForm from "../BaseForm/BaseForm";
import localize from "../../../../../Localizations/Localize";

class ResetPassword extends React.Component {
    onSubmit = data => api.post('user/resetPassword', {
        ...data,
        code: this.props.code
    })
        .then(() => {
            this.props.history.push('/');
            this.props.setModal('signIn');
        });

    render() {
        const { setModal, getString } = this.props;

        return (
            <BaseForm
                close={() => setModal(false)}
                onSubmit={this.onSubmit}
                validationSchema={yup.object().shape({
                    password: yup.string().min(8, { message: getString('authorization.passwordError') }).required(),
                    confirmPassword: yup.string().oneOf([yup.ref('password')], {
                        message: getString('authorization.confirmPasswordError')
                    }).required(),
                    reCaptchaToken: yup.string().required()
                })}
                subtitle={getString('authorization.enterNewPassword')}
                buttons={{ submitTitle: getString('authorization.reset') }}
            >
                <Input type="password" required name="password" title={getString('authorization.password')} />
                <Input type="password" required name="confirmPassword" title={getString('authorization.confirmPassword')}/>
            </BaseForm>
        );
    }
}

export default localize(withRouter(ResetPassword));
