import React from 'react';
import cx from 'classnames';
import s from './BaseModal.module.scss';
import ReactModal from "react-modal";
import Button from "../Button/Button";

const BaseModal = ({
    opened, close, title, content, children, closeButtonTitle,
    className, containerClassName, centered, buttons
}) => (
    <ReactModal
        className={cx(s.modal, centered && s.centered, className)}
        overlayClassName={cx(s.modalContainer, containerClassName)}
        isOpen={opened}
        onRequestClose={close}
    >
        <div className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={s.content}>
            {children || content}
        </div>
        {buttons === false || buttons || <Button width="140px" title={closeButtonTitle} onClick={close} />}
    </ReactModal>
);

export default BaseModal;
