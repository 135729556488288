import React from 'react';
import cx from 'classnames';
import s from './Captcha.module.scss';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../config';
import FastField from "../FastField/FastField";

class Captcha extends React.Component {
    componentDidUpdate(prevProps) {
        if (prevProps.field.value !== this.props.field.value && this.props.field.value === null) {
            this.captcha.reset();
        }
    }

    componentWillMount() {
        window.recaptchaOptions = {
            lang: this.props.language,
            removeOnUnmount: true
        };
    }

    render() {
        const { field, form, ...props} = this.props;
        var invalid = false;
        var validation = form.submitCount || form.touched[field.name];
        if (validation) {
            invalid = form.errors[field.name];
        }
        return (
            <ReCAPTCHA
                {...props}
                ref={e => this.captcha = e}
                onChange={e => form.setFieldValue(field.name, e)}
                sitekey={config.recaptchaSiteKey}
                className={cx(invalid && s.invalid)}
            />
        );
    }
}

const Wrapper = (props) => <FastField {...props} component={Captcha} />;

export default Wrapper;
