import React, {Component} from 'react';
import s from './BaseForm.module.scss';
import {Form as FormikForm, Formik} from "formik";
import Captcha from "../../../../../Components/Captcha/Captcha";
import cx from "classnames";
import Button from "../../../../../Components/Button/Button";
import {LocalizationContext} from "../../../../../Localizations/LocalizationContext";
import localize from "../../../../../Localizations/Localize";

class BaseForm extends Component {
    state = {};

    onSubmit = data => this.props.onSubmit(data)
        .catch(response => {
            this.formik.setState({ values: {
                    ...this.formik.state.values,
                    reCaptchaToken: null
                }});
            response.json().then(error => this.setState({ error }))
        });

    render() {
        const { language } = this.context;
        const { validationSchema, close, children, buttons, subtitle, getString } = this.props;
        const { error } = this.state;

        var { submitTitle, right, cancelTitle } = buttons || {};

        return (
            <div className={s.container}>
                <Formik onSubmit={this.onSubmit} validationSchema={validationSchema} ref={e => this.formik = e}>
                    <FormikForm>
                        {subtitle && <div className={s.subtitle}>
                            {subtitle}
                        </div>}
                        {React.Children.map(children, e => <div className={s.row}>{e}</div>)}
                        <div className={s.row}>
                            <Captcha language={language} key={language} name="reCaptchaToken"/>
                        </div>
                        <div className={cx(s.error, error && s.visible)}>
                            {error}
                        </div>
                        <div className={s.buttons}>
                            <span>
                                <Button width="150px"
                                        title={cancelTitle || getString('authorization.cancel')}
                                        onClick={close}
                                        type="cancel"
                                />
                                <Button width="165px"
                                        title={submitTitle || getString('authorization.confirm')}
                                        htmlType="submit"
                                />
                            </span>
                            {right}
                        </div>
                    </FormikForm>
                </Formik>
            </div>
        );
    }
}

BaseForm.contextType = LocalizationContext;

export default localize(BaseForm);
