import React from 'react';
import {storageRole} from "../constants";

const AuthorizationContext = React.createContext({
    role: localStorage.getItem(storageRole),
    setRole: () =>  {}
});

class Authorization extends React.Component {
    state = {
        role: localStorage.getItem(storageRole)
    };

    setRole = role =>  this.setState({ role });

    getContext = () => ({
        role: this.state.role,
        setRole: this.setRole
    });

    render() {
        window.setRole = this.setRole;
        return (
            <AuthorizationContext.Provider value={this.getContext()}>
                {this.props.children}
            </AuthorizationContext.Provider>
        );
    }
}

export { Authorization, AuthorizationContext };
