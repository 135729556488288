import React from 'react';
import s from './EventPicture.module.scss';
import { images } from './Helper';
import cx from 'classnames';
import {getDataUrl} from "../../Helpers/FormatHelper";

const EventPicture = ({ src, type, small, big, hideForDesktop, icon,
    pictureFormat, iconFormat  }) => (
    <div className={cx(s.container, {
        [s.hideForDesktop]: hideForDesktop,
        [s.big]: big,
        [s.small]: small
    })}>
        <img
            className={s.picture}
            src={src ? getDataUrl(src, pictureFormat) : (images[type] || images["Disco"])}
        />
        {icon && <img className={s.icon} src={getDataUrl(icon, iconFormat)} /> }
    </div>
);

export default EventPicture;
